import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <p>Page not Found</p>
  </Layout>
);

export default NotFoundPage;
